@use '@angular/cdk';
@import "bootstrap-theme";
@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./mixins/spacing-generators";
@import "./checkout-columns-grid";
@import "./popover";
@import "./mixins/media-queries";
@import "./bootstrap-theme-fixes.scss";
@import "./hidden-visible-utilities.scss";
@import "~maplibre-gl/dist/maplibre-gl.css";

@include cdk.a11y-visually-hidden();

$space-amounts: (0, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45);

@include generate-margin-classes(left, $space-amounts);
@include generate-margin-classes(right, $space-amounts);
@include generate-margin-classes(bottom, $space-amounts);
@include generate-margin-classes(top, $space-amounts);
@include generate-margin-all($space-amounts);

@include generate-padding-classes(left, $space-amounts);
@include generate-padding-classes(right, $space-amounts);
@include generate-padding-classes(bottom, $space-amounts);
@include generate-padding-classes(top, $space-amounts);
@include generate-padding-all($space-amounts);

hr {
  margin-bottom: 1rem;
  margin-top: 1rem;

  &.small-margin {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &.no-margin {
    margin: 0;
  }

  &.right-extended {
    margin-right: -9999px;
    position: relative;
  }

  &.left-extended {
    margin-left: -9999px;
  }

  &.tiled-menu {
    @include laptop-max {
      margin-right: unset !important;
      margin-left: unset !important;
    }
  }
}

.form-group.required {
  & .col-form-label::after {
    content: "*";
    padding-left: 3px;
    color: $red;
  }
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.padded-left {
  padding-left: 20px;
}

.padded-right {
  padding-right: 20px;
}

.padded-right-sm-down {
  @media screen and (max-width: 1300px) {
    padding-right: 20px !important;
  }
}

.padded-left-sm-down {
  @media screen and (max-width: 1300px) {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .padded-left,
  .padded-left-sm-down {
    &.compact-card-padding {
      padding-left: 25px !important;
    }
  }

  .padded-right,
  .padded-right-sm-down {
    &.compact-card-padding {
      padding-right: 25px !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .padded-left,
  .padded-left-xs {
    padding-left: 20px !important;
  }

  .padded-right,
  .padded-right-xs {
    padding-right: 20px !important;
  }
}

.padded-vert-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padded-horiz-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.font-primary {
  font-family: $font-primary;
}

.font-secondary {
  font-family: $font-secondary;
}

.font-tertiary {
  font-family: $font-tertiary !important;
}

.step-legend {
  font-size: 1.25rem;
  border-bottom: 0;
  margin: 0;
}

.fw-700 {
  font-weight: 700;
}

.top-right-badge {
  position: relative;

  &:not([data-count="0"]) {
    &::after {
      content: attr(data-count);
      position: absolute;
      background: #d9534f;
      color: #ffffff;
      padding: 3px 5px 5px;
      right: -7px;
      top: -9px;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      border-radius: 0.25em;
    }
  }

  &.concise {
    &::after {
      font-size: 0.6rem;
      right: 3px;
      top: 4px;
      padding: 2px 4px 4px;
    }
  }
}

i.top-right-badge::after {
  font-size: 0.6rem;
  right: -6px;
  top: -8px;
  padding: 2px 4px 4px;
}

textarea.vert-resize {
  resize: vertical;
}

.no-left-padding {
  padding-left: 0;
}

.no-left-padding-md-up {
  @media screen and (min-width: 992px) {
    padding-left: 0;
  }
}

.no-right-padding {
  padding-right: 0;
}

.no-right-padding-md-up {
  @media screen and (min-width: 992px) {
    padding-right: 0;
  }
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-top-margin {
  margin-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.no-border-left {
  border-left: 0;
}

.normal-weight {
  font-weight: normal;
}

.overlay {
  background: $lightgrey;
}

.text-grayed {
  color: rgba(0, 0, 0, 0.3);
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.full-width {
  width: 100%;
}

.full-width-important {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.container.padded {
  padding-top: 50px;
  padding-bottom: 50px;

  @include tablet-min {
    padding-left: 0;
    padding-right: 0;

    & > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include mobile {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

rating span {
  outline: none; // This is to remove the unwanted outline in rating-star component.
}

.primary-bg-color {
  background-color: white;
}

.primary-bg-border-color {
  border-color: white;
}

.primary-text-color {
  color: #333333;
}

.primary-text-border-color {
  border-color: #333333;
}

.secondary-bg-color {
  background-color: #333333;
}

.secondary-text-color {
  color: #fff;
}

.secondary-text-inverse-color {
  color: #333333;
}

.secondary-bg-inverse-color {
  background-color: #fff;

  &:after,
  &:before {
    background-color: #fff;
  }
}

.alpha-middle {
  background-color: $overlay-darker;
}

.action-bg-color {
  background-color: $brand-primary;
  fill: $brand-primary;
  fill-opacity: 1 !important;
}

.action-text-color {
  color: $brand-primary;
}

.action-border-color {
  border-color: $brand-primary;
}

.action-inverse-bg-color {
  background-color: white;
}

.action-inverse-text-color {
  color: white;
}

.action-inverse-border-color {
  border-color: white;
}

.selection-bg-color {
}

.selection-border-color {
}

.secondary-bg {
  // In general class names are subject to change after the demo
  background-color: #e0a200;
}

.intro-highlight-text-color {
  color: gold;
}

.intro-highlight-bg-color {
  background-color: gold;
}
.intro-highlight-inverse-text-color {
  color: red;
}

.intro-highlight-inverse-bg-color {
  background-color: red;
}

.secondary-text {
  color: white;
}

.step-entry {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.loading-button {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    border-radius: inherit;
    opacity: 0.9;
    display: block;
    content: "";
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: inherit;
    font-size: inherit;
    display: block;
    content: "\f110";
    animation: pseudo-fa-spin 2s infinite linear;
    font-family: "fts-fa-icons";
  }
}

.explain-required-sign {
  color: $red;

  &:after {
    content: "required";
    color: #000;
    margin-left: 3px;
  }
}

.page-height-filler {
  height: 300px;
}

@keyframes pseudo-fa-spin {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.no-scroll {
  overflow: hidden;
}

.shadowed {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2) !important;
}

.borderless {
  border-color: transparent !important;
}

.btn-header {
  color: inherit;
  line-height: 1.8rem;

  &:hover,
  &:focus {
    color: inherit;
  }

  &.btn-menu-link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.container {
  width: 1280px !important;

  @media screen and (max-width: 1279px) {
    width: 100% !important;
  }
}

.btn-menu {
  @media screen and (max-width: 1300px) {
    padding-left: 20px !important;
  }
}

.credit-card-icon-colored {
  &.fa-cc-visa {
    color: #0157a2;
  }

  &.fa-cc-mastercard {
    color: #0a3a82;
  }

  &.fa-cc-amex {
    color: #007bc1;
  }

  &.fa-cc-discover {
    color: #f68121;
  }

  &.fa-cc-jcb {
    color: #003a8f;
  }

  &.fa-cc-diners-club {
    color: #004a97;
  }
}

.center-panel {
  .featured-content {
    margin-bottom: 2rem;
    height: 240px;
  }
}

.wrap-text {
  white-space: normal;
  word-break: break-word;
}

.alert-form {
  font-weight: 600;

  &.alert-danger {
    color: #fff;
    background: #d9534f;
  }

  &.alert-success {
    color: #fff !important;
    background: #5cb85c !important;
  }
}

.calories-display {
  font-size: 16px !important;
}

.item-size-options {
  font-size: 15px;
}

.ingredient-options {
  font-size: 15px;
}

.modal-buttons {
  min-width: 50%;
  display: inline-flex;
  flex-direction: column;
  text-align: center;

  .btn {
    flex: 1 0 auto;
    margin-bottom: 1rem;
    font-weight: 400;
  }
}

.modal-header.alert {
  margin-bottom: 0;
}

.group-order-checkbox-wrapper {
  display: table;
  margin: auto;
  padding-bottom: 2rem;
}

*[tabindex="-1"] {
  outline: none !important;
}

body {
  &.mobile-overlay {
    max-height: 100vh !important;
    overflow-y: hidden !important;
  }
}

button > fa-icon {
  pointer-events: none;
}

.font-size-large {
  font-size: large;
}

.font-size-larger {
  font-size: larger;
}

.font-size-2 {
  font-size: 2em;
}

.tsep-form-group {
  @extend .form-group;

  input {
    @extend .form-control;
  }
}

.m-0 {
  margin: 0;
}

.fa-icon-svg-vertical-top > svg {
  vertical-align: baseline !important;
}

.border-danger {
  border-color: $danger;
}

a {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: underline;
}

.badge {
  border-color: transparent;
}

.w-fit-content {
  width: fit-content;
}

.fts-flash {
  animation: blinker 1000ms linear 0s 1;
  &.infinite {
    animation-timing-function: ease-in-out !important;
    animation-iteration-count: infinite !important;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.checkout-upsell-modal {
  @include laptop-min {
    &.modal-lg {
      max-width: 900px;
    }
  }
}

.item-editor-modal {
  @include laptop-min {
    &.modal-lg {
      max-width: 1100px;
    }
  }

  @include tablet {
    &.modal-lg {
      max-width: 700px;
    }
  }
}

#fts-go-cart-enroll-container input {
  width: 2.5rem !important;
}

.no-list-style {
  ul {
    list-style: none;
    padding-left: 0;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

@keyframes blur {
  0% {
    filter: blur(1px);
  }
  50% {
    filter: blur(3px);
  }
  100% {
    filter: blur(1px);
  }
}

.blury {
  animation: blur 1s ease-in-out infinite;
}

.calendar-date-popover {
  box-shadow: none !important;
  border-radius: 5px !important;
  text-align: center;

  .popover-body {
    background-color: black !important;
    color: white !important;
    padding: 0.25rem 0.75rem !important;
    border-radius: 5px !important;
  }

  &.bs-popover-top > .arrow::after {
    border-top-color: black !important;
  }

  &.bs-popover-left > .arrow::after {
    border-left-color: black !important;
  }

  &.bs-popover-right > .arrow::after {
    border-right-color: black !important;
  }

  &.bs-popover-bottom > .arrow::after {
    border-bottom-color: black !important;
  }
}

.no-border-start {
  border-left: none;
}

.scan-code {
  position: relative;
  width: 125px;
  height: 125px;
}

.scan-code__angle:before,
.scan-code__angle:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-color: #9a9a9a;
  border-width: 0;
}

.scan-code__angle:before {
  border-left-width: 2px;
  left: 0;
}

.scan-code__angle:after {
  border-right-width: 2px;
  right: 0;
}

.scan-code__angle--top:before,
.scan-code__angle--top:after {
  top: 0;
  border-top-width: 2px;
}

.scan-code__angle--bottom:before,
.scan-code__angle--bottom:after {
  bottom: 0;
  border-bottom-width: 2px;
}

.scan-code__angle--top:before {
  border-top-left-radius: 2px;
}

.scan-code__angle--top:after {
  border-top-right-radius: 2px;
}

.scan-code__angle--bottom:before {
  border-bottom-left-radius: 2px;
}

.scan-code__angle--bottom:after {
  border-bottom-right-radius: 2px;
}
