@import "./variables";

.column-groups-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.column + .column {
  border-left: 1px solid $overlay-dark;

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    border-left: none;
    margin-top: 60px;
  }

  @media only screen and (max-width: 767px) {
    border-left: none;
    margin-top: 60px;
  }
}

.column-group {
  flex: 0 0 33.2%;

  .column {
    width: 100%;
    padding: 0 20px;
  }

  &.two-columned {
    flex: 0 0 66.7%;
    display: flex;

    .column {
      flex: 0 1 50%;
    }
  }

  & + & {
    border-left: 1px solid $overlay-dark;
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    flex: 0 0 50%;

    &.two-columned {
      flex: 0 0 50%;
      flex-wrap: wrap;

      .column {
        flex: 0 0 100%;
      }
    }

    & + & + & {
      border-left: none;
      margin-top: 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    flex: 0 0 100%;

    &.two-columned {
      flex: 0 0 100%;
      flex-wrap: wrap;

      .column {
        flex: 0 0 100%;
      }
    }

    .column {
      padding: 0;
    }

    & + & {
      border-left: none;
      margin-top: 60px;
    }
  }
}
