@import "variables";
@import "./mixins/media-queries";

// Helper variables
$btn-border-radius: 0;

// Bootstrap variables
$font-size-base: 1rem;
$primary: #00808a;
$warning: #f0ad4e;
$state-warning-text: #d48d12; // cound not found
$info: #aaa;
$success: #5cb85c;
$danger: $red;
$btn-border-radius: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-font-weight: 500;
$font-family-base: $font-primary;
$font-family-monospace: $font-tertiary;
$hr-border-color: hsla(0, 0%, 50%, 0.3);
$container-max-widths: (
  md: 100%,
  lg: 100%,
);
$padding-xs-vertical: 2px; // cound not found
$padding-xs-horizontal: 7px; // cound not found
$btn-success-color: #00808a;
$btn-success-bg: rgba(0, 0, 0, 0);
$btn-success-border: rgba(150, 150, 150, 0.4);
$btn-info-bg: $overlay-darker;
$btn-primary-border: transparent; // custom fix
$btn-info-border: transparent;
// custom fix
$btn-warning-border: transparent;
// custom fix
$btn-danger-border: transparent; // custom fix
$headings-font-weight: 300;
$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$alert-padding-y: 0.6rem;
$alert-padding-x: 1rem;
$alert-border-radius: 0;
$input-bg: white;
$input-border: $overlay-darker;
$input-color: inherit;
$input-border-radius: 0 !default;
$input-focus-border-color: #66afe9;
$input-focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
$input-btn-focus-color: none;
$modal-footer-border-color: $overlay-dark;
$modal-header-border-color: $overlay-dark;
$modal-content-inner-border-radius: 0;
$modal-sm: 400px;
$modal-md: 600px;
$modal-lg: 800px;
$close-text-shadow: none;
$enable-shadows: true;
$popover-body-padding-y: 0;
$popover-body-padding-x: 0;
$popover-border-color: transparent;
$popover-max-width: 90%;
$popover-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
$zindex-popover: 999;
$progress-bg: $overlay-darker;
$progress-border-radius: 0;
$lead-font-size: 24px; //bootstra 3 lead-font-size value
$line-height-base: 1.428571429; //bootstra 3 line-height value
// Icon fonts path
// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
