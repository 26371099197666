@import "_input-group.scss";

$btn-block-spacing-y: 0.5rem !default;

.ngb-dp-week {
  border-radius: 0 !important;
  background-color: #f8f9fa !important;
}

.ngb-tp-input {
  width: inherit !important;
}

.has-error {
  color: $danger;
}

.form-control.is-invalid {
  background-image: none;
  padding-right: 6px;
}

.alert-warning {
  background-color: $lightgrey;
  border: $warning;
}

.alert-danger {
  background-color: $lightgrey;
  border-color: $danger;
}

.alert-info {
  background-color: $lightgrey;
  border-color: $info;
}

.alert-success {
  background-color: $lightgrey;
  border-color: $success;
}

// Additional "fixes"
.btn {
  font-family: $font-secondary;
}

.btn-link {
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  &.btn-undecorated {
    color: inherit;
    text-decoration: none;
  }

  &.dotted {
    text-decoration: none;
    border-bottom: 1px dotted;
  }
}

html {
  scroll-behavior: auto !important;
  font-size: $font-size-base;
  overflow-x: hidden !important;
}

body {
  font-weight: 300;
}

main {
  overflow-x: hidden !important;
  @include laptop-min {
    overflow-x: unset !important;
  }
  @include tablet-min {
    &.no-overflow-x {
      overflow-x: unset !important;
    }
  }
  &.tiled-menu {
    overflow-x: unset !important;
  }
}

.btn-rounded {
  border-radius: 15px;
}

.btn,
.btn-block {
  &.text-left {
    text-align: left !important;
  }
}

.btn-block {
  display: block !important;
  width: 100% !important;

  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

.alert {
  border: 0;
  color: inherit !important;
  font-size: 0.9rem;
  white-space: normal;
  font-weight: 500;
  white-space: normal;
  word-break: break-word;
}

.label {
  padding: 0.1em 0.3em;
}

.form-control {
  appearance: auto;
  box-shadow: none;
  padding: 6px;

  &:focus {
    outline: auto !important;
  }
}

.btn:active,
.btn.active {
  box-shadow: none !important;
}

timepicker,
.well {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  border: 0;
  border-radius: 0;
}

.modal-open {
  max-height: 100vh;
  overflow: visible !important;
}

.modal-body {
  background-color: $lightgrey;
  padding: 2rem 2rem 3rem;
  text-align: center !important;

  &.transparent {
    background-color: transparent;
  }
}

.modal-dialog.transparent {
  & .modal-content {
    background-color: transparent !important;
  }
}

.modal-dialog.modal-centered {
  top: 20%;

  @include mobile {
    top: 0;
  }
}

.modal-dialog.no-shadow {
  & .modal-body {
    box-shadow: none;
  }
}

.modal {
  & .popover {
    z-index: 1660;
  }
}

.modal-sm {
  @include tablet-min {
    width: 400px;
  }
}

.text-muted {
  color: inherit !important;
  opacity: 0.75;
}

.form-text {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 0.9rem !important;
  display: block;
  margin-top: 0.25rem;
  color: inherit !important;
}

.form-field-help {
  color: inherit !important;
  opacity: 0.9;
  margin-top: 5px;
  margin-bottom: 10px;

  &.font-size-xsm {
    font-size: 12px !important;
  }
}

.modal-header {
  padding: 2rem 2rem 0.5rem;
  min-height: 7rem;
  display: flex;
  align-items: flex-end;
  text-align: center;

  .modal-title {
    font-size: 1.4rem;
    flex: 1 1 100%;
  }

  .close {
    position: absolute;
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    top: 0.15rem;
    right: 0.15rem;
    opacity: 0.8;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
      opacity: 1;
    }
  }
}

.modal-footer {
  z-index: 1661;
}

.popover {
  border-radius: 0;
  padding: 0;
  z-index: 5;
  @media screen and (min-width: 768px) {
    max-width: unset;
    width: fit-content;
  }
}

.popover-content {
  padding: 0;
  max-width: 300px;
}

.btn {
  &:disabled,
  &[disabled] {
    opacity: 0.6;

    &.opaque {
      opacity: 1 !important;
    }
  }
}

.bg-danger {
  background: $red;
}

.text-danger {
  color: $danger;

  &:hover,
  &:focus {
    color: $danger;
  }
}

.has-error re-captcha {
  display: inline-block;
  border: 1px solid $danger;
}

.modal-content {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

.col-form-label {
  padding: 0px;
}

.slideInUp {
  top: -100px;
  animation: ease-in-out 0.3s fadeIn;
  &.show {
    top: 0;
  }
}

@keyframes fadeIn {
  from {
    top: -50px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.popover-arrow-shadow .arrow::after {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 22px;
  margin-bottom: 11px;
}

.form-horizontal .col-form-label {
  padding-top: 7px;
}

.small {
  font-size: 87%;
}

legend {
  margin-bottom: 22px;
}

.btn:focus {
  outline: 5px auto;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.text-success {
  color: rgb(60, 119, 60) !important;
}

/* Bootstrap 5 deprecations/renames */
.sr-only {
  @extend .visually-hidden;
}

.sr-only-focusable {
  @extend .visually-hidden-focusable;
}

.font-weight-bold {
  @extend .fw-bold;
}

.text-left {
  @extend .text-start;
}

.text-right {
  @extend .text-end;
}

.fload-left {
  @extend .float-start;
}

.fload-right {
  @extend .float-end;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.col-form-label,
.col-form-label-lg,
.col-form-label-sm {
  @extend .form-label;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  @extend .row;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
