@import "./media-queries";

@mixin generate-margin-classes($direction: left, $default-space-amounts: ()) {
  @each $space in $default-space-amounts {
    .margin-#{$direction}-#{$space} {
      margin-#{$direction}: #{$space}px;
      &-xs {
        @include mobile {
          margin-#{$direction}: #{$space}px;
        }
      }
      &-sm {
        @include tablet {
          margin-#{$direction}: #{$space}px;
        }
        &-min {
          @include tablet-min {
            margin-#{$direction}: #{$space}px;
          }
        }
      }
      &-md {
        @include laptop {
          margin-#{$direction}: #{$space}px;
        }
        &-min {
          @include laptop-min {
            margin-#{$direction}: #{$space}px;
          }
        }
      }
      &-lg {
        @include desktop {
          margin-#{$direction}: #{$space}px;
        }
      }
    }
  }
}
@mixin generate-margin-all($default-space-amounts: ()) {
  @each $space in $default-space-amounts {
    .margin-#{$space} {
      margin: #{$space}px;
    }
  }
}
@mixin generate-padding-classes($direction: left, $default-space-amounts: ()) {
  @each $space in $default-space-amounts {
    .padded-#{$direction}-#{$space} {
      padding-#{$direction}: #{$space}px;
      &-xs {
        @include mobile {
          padding-#{$direction}: #{$space}px;
        }
      }
      &-sm {
        @include tablet {
          padding-#{$direction}: #{$space}px;
        }
        &-min {
          @include tablet-min {
            padding-#{$direction}: #{$space}px;
          }
        }
      }
      &-md {
        @include laptop {
          padding-#{$direction}: #{$space}px;
        }
        &-min {
          @include laptop-min {
            padding-#{$direction}: #{$space}px;
          }
        }
      }
      &-lg {
        @include desktop {
          padding-#{$direction}: #{$space}px;
        }
      }
    }
  }
}
@mixin generate-padding-all($default-space-amounts: ()) {
  @each $space in $default-space-amounts {
    .padding-#{$space} {
      padding: #{$space}px;
    }
  }
}
