$brand-primary: #009ea5;
$brand-primary-active: #00757a;

$font-primary: "Roboto Condensed", sans-serif;
$font-secondary: "Oswald", sans-serif;
$font-tertiary: "Share Tech Mono", monospace;

$lightgrey: hsla(0, 0%, 50%, 0.15);
$overlay-dark: hsla(0, 0%, 50%, 0.3);
$overlay-darker: hsla(0, 0%, 50%, 0.5); // TODO
$grey: rgba(150, 150, 150, 1);
$red: #b12722;
$deep-red: rgb(205, 22, 63);

$progress-bar-height: 0.45rem;
$progress-bar-bullet-size: 1.15rem;
