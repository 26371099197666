$screen-xs-portrait: 420px;
$screen-xs: 576px;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

@mixin xs-mobile {
  @media (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

@mixin mobile-min {
  @media (min-width: #{$screen-xs}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

@mixin tablet-min {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

@mixin tablet-max {
  @media (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

@mixin laptop-min {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

@mixin laptop-max {
  @media (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin mobile-portrait {
  @media (max-width: $screen-xs-portrait) and (orientation: portrait) {
    @content;
  }
}
