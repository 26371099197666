@function shadow-color($alpha) {
  @return rgba(0, 0, 0, $alpha);
}

@mixin arrow($host-class, $border-width, $z-index, $border-color: "") {
  #{$host-class} {
    content: " ";
    position: absolute;
    border-width: $border-width;
    border-style: solid;
    z-index: $z-index;

    @if ($border-color != "") {
      border-color: $border-color;
    }

    &.arrow-top {
      border-bottom-width: 0;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      top: 100%;
    }

    &.arrow-bottom {
      border-top-width: 0;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      bottom: 100%;
    }

    &.arrow-left {
      border-left-width: 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      right: 100%;
    }

    &.arrow-center {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.popover-container {
  $box-shadow-alpha: 0.2;
  $box-shadow: 0px 0px 2px 1px shadow-color($box-shadow-alpha);
  $arrow-shadow-border-color: shadow-color($box-shadow-alpha - 0.1);
  $z-index: 4;

  position: absolute;
  box-shadow: $box-shadow;
  right: 0;
  z-index: 1200;

  @include arrow(".arrow", 11px, $z-index);
  @include arrow(".arrow-shadow", 15px, $z-index - 1, $arrow-shadow-border-color);
}

.popover-template {
  display: none;
}
